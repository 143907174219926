export function getFormatDate(date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
}

/**获取前30天的日期 */
export function getBeforeMonthDay() {
  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  let list = [];
  for (let i = 0; i < 29; i++) {
    date.setDate(date.getDate() + 1);
    list.push(getFormatDate(date).split(" ")[0]);
  }
  return list.reverse().map((item, index) => {
    return {
      value: item,
      label: item,
    };
  });
}

/**获取前8周日期 */
export function getBeforeWeek() {
  let date = new Date();
  let monDay = date.getDay() - 1;
  date.setDate(date.getDate() - monDay + 6);
  let list = [];
  let pack = function (value) {
    return value < 10 ? `0${value}` : value;
  };
  for (let i = 0; i < 8; i++) {
    date.setDate(date.getDate() - 7);
    let afterDate = `${date.getFullYear()}/${pack(date.getMonth() + 1)}/${pack(date.getDate())}`;
    date.setDate(date.getDate() - 6);
    let beforeDate = `${date.getFullYear()}/${pack(date.getMonth() + 1)}/${pack(date.getDate())}`;
    date.setDate(date.getDate() + 6);
    list.push(`${beforeDate}-${afterDate}`);
  }
  return list.map((item, index) => {
    let label = item.substr(5, 6) + item.substr(16, 5);
    let value = item.replace(/\//g, "");
    return { label, value };
  });
}

/**获取前6个月 */
export function getBeforeMonth() {
  let date = new Date();
  let list = [];
  for (let i = 0; i < 6; i++) {
    date.setMonth(date.getMonth() - 1);
    let str = getFormatDate(date).substr(0, 7);
    list.push(str);
  }
  return list.map((item, index) => {
    let value = item.replace("-", "");
    let label = item;
    return { value, label };
  });
}
